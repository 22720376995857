<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="의견" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline>
            <c-btn
              v-if="!disabled"
              :url="updateOpinionUrl"
              :isSubmit="isSaveOpin"
              :param="nearAccidentInfo"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveNearAccident"
              @btnCallback="saveNearAccidentCallback" 
            />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="단기계획"
              name="shortPlan"
              v-model="nearAccidentInfo.shortPlan">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="중장기 계획"
              name="longPlan"
              v-model="nearAccidentInfo.longPlan">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              label="담당자 의견"
              name="managerOpinion"
              v-model="nearAccidentInfo.managerOpinion">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="tableImpr"
        title="개선 목록"
        :isTitle="true"
        :columns="gridImpr.columns"
        :data="nearAccidentInfo.nearAccidentPreventionModels"
        :gridHeight="gridImpr.height"
        :merge="gridImpr.merge"
        selection="multiple"
        rowKey="iimNearCausesId"
        :isExcelDown="false"
        :gridHeightAuto="true"
        :usePaging="false"
        :filtering="false"
        :columnSetting="false"
        :editable="editable && !disabled"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="add" />
            <c-btn
              v-if="editable && !disabled && nearAccidentInfo.nearAccidentPreventionModels.length > 0"
              :url="updateCauseUrl"
              :isSubmit="isSaveCause"
              :param="nearAccidentInfo.nearAccidentPreventionModels"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveNearImpr"
              @btnCallback="saveNearImprCallback" 
            />
            <c-btn v-if="editable && !disabled && nearAccidentInfo.nearAccidentPreventionModels.length > 0" label="삭제" :showLoading="false" icon="remove" @btnClicked="removeImpr" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="editable&&!disabled"
              :isImmShow="true"
              :requestContentsCols="requestContentsCols"
              tableKey="causesNo"
              ibmTaskTypeCd="ITT0000045"
              ibmTaskUnderTypeCd="ITTU000070"
              @imprChange="imprChange"
            />
          </template>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup"></div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'near-cause-prevention',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        iimStatusNearCd: '',
        approvalStatusCd: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      nearAccidentInfo: {
        iimNearAccidentNo: '',
        iimNearAccidentName: '',
        iimNearKindName: '',
        occurrenceDt: '',
        shortPlan: '',
        longPlan: '',
        managerOpinion: '',
        nearAccidentPreventionModels: [],
      },
      gridImpr: {
        columns: [
          {
            required: true,
            name: 'iimNearCauseTypeCd',
            field: 'iimNearCauseTypeCd',
            label: '원인구분',
            align: 'center',
            style: 'width:30%',
            type: 'select',
            codeGroupCd: 'IIM_NEAR_CAUSE_DIVISION_CD',
            sortable: false,
          },
          {
            required: true,
            name: 'iimNearAccidentCause',
            field: 'iimNearAccidentCause',
            label: '사고원인',
            align: 'left',
            type: 'text',
            style: 'width:60%',
            colClass: 6,
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '',
            align: 'center',
            style: 'width:10%',
            type: 'custom',
            sortable: false
          },
        ],
        
        data: [],
        height: '400px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        popupParam: {},
        closeCallback: null,
      },
      isSaveOpin: false,
      isSaveCause: false,
      updateOpinionUrl: '',
      updateCauseUrl: '',
      detailInfoUrl: '',
      deleteCauseUrl: '',
    };
  },
  computed: {
    disabled() {
      return (this.editable && Boolean(this.popupParam.iimStatusNearCd) && this.popupParam.iimStatusNearCd === 'ISNC000002') || this.popupParam.approvalStatusCd === 'ASC9999999';
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['iimNearCauseTypeName', 'iimNearAccidentCause'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
  },
  watch: {
    'count'() {
      this.getStdInfo();
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailInfoUrl = selectConfig.sop.iim.accident.near.get.url;
      this.updateOpinionUrl = transactionConfig.sop.iim.accident.near.update.url;
      this.updateCauseUrl = transactionConfig.sop.iim.accident.near.prevention.update.cause.url;
      this.deleteCauseUrl = transactionConfig.sop.iim.accident.near.prevention.delete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {   // 아차사고 기본정보, 의견, 개선 목록 받아오는 부분
      if (this.popupParam.iimNearAccidentId) {
        this.$http.url = this.$format(this.detailInfoUrl, this.popupParam.iimNearAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.nearAccidentInfo = _result.data;
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    saveNearAccident() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccidentInfo.regUserId = this.$store.getters.user.userId
              this.nearAccidentInfo.chgUserId = this.$store.getters.user.userId              
              this.isSaveOpin = !this.isSaveOpin;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveNearAccidentCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    saveNearImpr() {
      if (this.$comm.validTable(this.gridImpr.columns, this.nearAccidentInfo.nearAccidentPreventionModels)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveCause = !this.isSaveCause;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveNearImprCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    /* eslint-disable no-unused-vars */ 
    add() {
      this.nearAccidentInfo.nearAccidentPreventionModels.splice(0, 0, {
        iimNearAccidentId: this.popupParam.iimNearAccidentId,
        iimNearCausesId: uid(),    // uuid와 동일한것
        iimNearCauseTypeCd: null,
        iimNearCauseTypeName: '',
        iimImprovingMeasures: '',
        sopImprovementIds: '', 
        ibmStepNames: '',
        regUserId: this.$store.getters.user.userId, 
        editFlag: 'C',
      })
    },
    removeImpr() {
      let selectData = this.$refs['tableImpr'].selected;
      if (!selectData || selectData.length === 0) {   //삭제하려고 선택한 값이 하나도 없을 경우
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteCauseUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              this.$_.forEach(selectData, item => {
                // 내가 선택한 제외 목록이 실제로 없애야 함으로
                this.nearAccidentInfo.nearAccidentPreventionModels = this.$_.reject(this.nearAccidentInfo.nearAccidentPreventionModels, item)
              })
              this.$refs['tableImpr'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
